import React from "react";
import { useEffect } from "react"

const defaultProps = { fullWidthResponsive: true }

const AdContainer = (props: { adSlot: string, children?: React.ReactNode, fullWidthResponsive?: boolean }) => {
    const { adSlot, children, fullWidthResponsive } = { ...defaultProps, ...props };

    useEffect(() => {
        try {
          if (typeof window === 'object') {
            ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
          }
        } catch {
          // Pass
        }
    }, [adSlot]);

    return (
        <ins className="adsbygoogle"
            data-ad-client="ca-pub-4421121068934184"
            data-ad-slot={adSlot}
            data-ad-format="auto"
            data-full-width-responsive={fullWidthResponsive ? "true" : "false"}
        >
            { children }
        </ins>
    );
}

export default AdContainer;
